import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SQLitePorter } from '@ionic-native/sqlite-porter/ngx';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import {Product} from '../models/Product';
@Injectable({
  providedIn: 'root'
})
export class DbService {

  public handler: SQLiteObject;
  private isDbReady: BehaviorSubject<boolean> = new BehaviorSubject(false);
/*
  constructor(
    private platform: Platform, 
    private sqlite: SQLite, 
    private httpClient: HttpClient,
    private sqlPorter: SQLitePorter,
  ) {
    this.platform.ready().then(() => {
      let result=this.sqlite.create({
        name: 'primepos.db',
        location: 'default'
      });

      result.then((res)=>{
        this.handler=res;
        this.createTables();
      }).catch((error)=>{
        alert('ERROR'+JSON.stringify(error));
      });
    });
  }
  dbState() {
    return this.isDbReady.asObservable();
  }
 
  createTables(){
    console.log('THE QUERY',this.createProductsTable());
    this.handler.executeSql(this.createProductsTable(), [])
    this.handler.executeSql(this.createUsersTable(), [])
    .then(() => console.log('Executed SQL'))
    .catch(e => console.log("MYSQL ERROR",e));
  }

  
  createProductsTable(){
    let sql='CREATE TABLE IF NOT EXISTS "products" ("id"	INTEGER PRIMARY KEY AUTOINCREMENT,"name"	TEXT NOT NULL,"description"	TEXT,"price"	NUMERIC NOT NULL,"unit"	TEXT,"type"	TEXT DEFAULT "physical","barcode" text null)';
    return sql;
  }
  createUsersTable(){
    let sql='CREATE TABLE IF NOT EXISTS "users" ("id"	INTEGER PRIMARY KEY AUTOINCREMENT,"username"	TEXT NOT NULL,"password"	TEXT)';
    return sql;
  }
*/
}
